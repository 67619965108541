import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import ApplicationHistory from 'tio-common/components/tas/application-history';
import AppPage from 'tio-ui/components/app-page';
import NavTabs from 'tio-ui/components/nav-tabs';
import PaymentDetailsCard from 'tio-common/components/tas/payment-details-card';
import RouteTemplate from 'ember-route-template';
import TasPageHeader from 'tio-employee/components/tas/page-header';
import type TasApplicationModel from 'tio-common/models/tas-application';
interface TasApplicationHistorySignature {
    Args: {
        model: TasApplicationModel;
    };
}
export default RouteTemplate<TasApplicationHistorySignature>(template(`
    <AppPage @overrideTitle={{true}}>
      <:title>
        <TasPageHeader>
          <:title as |title|>
            <title.BackLink @route="authenticated.tas.dashboard">
              {{t "tas.default"}}
            </title.BackLink>
          </:title>
        </TasPageHeader>
      </:title>
      <:content>
        <div class="grid sm:grid-cols-4 gap-4">
          <div class="sm:col-span-3">
            <h1 class="tio-h1">{{@model.applicationName}}</h1>
            <NavTabs class="mb-4" as |navtabs|>
              <navtabs.item @route="authenticated.tas.applications.show" @model={{@model}}>
                {{t "tas.applications.default"}}
              </navtabs.item>
              <navtabs.item @route="authenticated.tas.applications.history" @model={{@model}}>
                {{t "tas.history.default"}}
              </navtabs.item>
            </NavTabs>
            <ApplicationHistory @history={{@model.history}} />
          </div>
          <PaymentDetailsCard
            @isPaid={{@model.isPaid}}
            @requestedTotal={{@model.requestedTotal}}
            @payments={{@model.paymentsHistory}}
          />
        </div>

      </:content>
    </AppPage>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
