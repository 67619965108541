import { template } from "@ember/template-compiler";
import { A } from '@ember/array';
import { action } from '@ember/object';
import { and, eq, or } from 'tio-ui/utilities';
import { currencyStringToNumber } from 'tio-common/utils/format';
import { fn } from '@ember/helper';
import { Input, Textarea, Select } from 'tio-ui/components/forms';
import { inputsForFields } from 'tio-common/utils/data/inputs-for-fields';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import Drawer from 'tio-ui/components/drawer';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import inputmask from 'tio-common/modifiers/inputmask';
import type Store from '@ember-data/store';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
import type TasApplication from 'tio-common/models/tas-application';
import type TasCourse from 'tio-common/models/tas-course';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import type { CustomFieldSignature } from 'tio-common/types/tuition-assistance';
interface AddEducationDrawerSignature {
    Args: {
        isOpen: boolean;
        onClose: () => void;
        application: TasApplication;
        course: TasCourse | null;
    };
    Element: HTMLDivElement;
}
export interface BuiltInput {
    id?: string;
    name: keyof TasCourse['fields'];
    label: string;
    type: string;
    inputType: string;
    required: boolean;
    values: (string | number)[];
    errors: string[];
    options?: {
        value: string;
        label: string;
    }[];
    selectionMode?: 'single' | 'multiple';
    custom?: boolean;
    visible: boolean;
    ordinal: number;
    province: string;
    rules: {
        type: 'string' | 'number';
        typeError?: 'string';
    };
    value_as_field_option?: boolean;
}
export interface CustomInput extends CustomFieldSignature {
    custom: boolean;
    name?: string;
}
export interface CourseFieldsHash {
    [key: string]: {
        values: (string | number)[];
    };
}
export default class AddEducationDrawerComponent extends Component<AddEducationDrawerSignature> {
    @service
    store: typeof Store;
    @tracked
    inputs: BuiltInput[] = A([]);
    @tracked
    customInputs: CustomInput[] = A([]);
    @tracked
    selectedKeys: string[] = [];
    @tracked
    trackedErrors: Record<string, string[]> = {};
    programTemplate: TasProgramTemplate;
    static createInputsForNewCourse(application1: TasApplication): BuiltInput[] {
        const builtInputs1 = [];
        const programTemplate1 = application1.tasProgramInstance.tasProgramTemplate;
        for(const field1 in programTemplate1.courseFieldsHash){
            // Merge the field data with input data
            let mergedInput1 = Object.assign(inputsForFields[field1 as keyof typeof inputsForFields], programTemplate1.fields[field1 as keyof typeof programTemplate.fields]);
            builtInputs1.push(mergedInput1);
        }
        return builtInputs1.sort((a1, b1)=>a1.ordinal - b1.ordinal) as BuiltInput[];
    }
    static createInputsToEditCourse(application1: TasApplication, course1: TasCourse): BuiltInput[] {
        const builtInputs1 = [];
        const programTemplate1 = application1.tasProgramInstance.tasProgramTemplate;
        const courseFieldsHash1 = programTemplate1.courseFieldsHash;
        for(const field1 in courseFieldsHash1){
            const templateInput1 = inputsForFields[field1 as keyof typeof inputsForFields] || {};
            const templateField1 = programTemplate1.fields[field1 as keyof typeof programTemplate.fields] || {};
            // Get the corresponding course field if it exists
            const courseField1 = course1.fields[field1 as keyof typeof course.fields];
            // Merge the course field values into the input, if the course field exists
            const mergedValues1 = courseField1?.values ? {
                values: courseField1.values
            } : {};
            // Merge the input data with the template field and course field values
            const mergedInput1 = Object.assign({}, templateInput1, templateField1, mergedValues1);
            builtInputs1.push(mergedInput1);
        }
        return builtInputs1.sort((a1, b1)=>a1.ordinal - b1.ordinal) as BuiltInput[];
    }
    static createCustomInputs(customFields1: CustomFieldSignature[]): CustomInput[] {
        return customFields1.map((customField1)=>({
                ...customField1,
                custom: true
            }));
    }
    static createErrorsObject(courseFieldsHash1: CourseFieldsHash[], customFields1: CustomFieldSignature[]): Record<string, string[]> {
        const errors1: Record<string, string[]> = {};
        for(const field1 in courseFieldsHash1){
            errors1[field1] = [];
        }
        for (const field1 of customFields1){
            errors1[field1.label] = [];
        }
        return errors1;
    }
    constructor(owner1: unknown, args1: AddEducationDrawerSignature['Args']){
        super(owner1, args1);
        const { application: application1, course: course1 } = args1;
        this.inputs = course1!.isNew ? new TrackedArray(AddEducationDrawerComponent.createInputsForNewCourse(application1)) : new TrackedArray(AddEducationDrawerComponent.createInputsToEditCourse(application1, course1!));
        this.customInputs = new TrackedArray(AddEducationDrawerComponent.createCustomInputs(course1!.customFields));
        this.programTemplate = application1.tasProgramInstance.tasProgramTemplate;
        this.trackedErrors = new TrackedObject(AddEducationDrawerComponent.createErrorsObject(this.programTemplate.courseFieldsHash, course1!.customFields));
    }
    inputClasses = {
        base: 'w-full mb-6'
    };
    get courseFields(): TasCourse['fields'] {
        return this.args.course!.fields;
    }
    getErrorForInput(input1: BuiltInput | CustomInput, trackedErrors1: Record<string, string[]>): string[] {
        if (input1.custom) {
            return trackedErrors1[input1.label] || [];
        } else {
            return trackedErrors1[input1.name!] || [];
        }
    }
    @action
    checkForErrors(): void {
        for (const input1 of this.inputs){
            // Check if the input is required
            if (input1.required) {
                if (input1.values.length === 0) {
                    this.trackedErrors[input1.name] = [
                        'This field is required'
                    ];
                } else {
                    delete this.trackedErrors[input1.name];
                }
            }
            // Check for correct type (if there are values)
            if (input1.values.length > 0) {
                const value1 = input1.values[0];
                // If the expected type is "number"
                if (input1.type === 'number') {
                    if (isNaN(Number(value1))) {
                        this.trackedErrors[input1.name] = [
                            'Value must be a number'
                        ];
                    } else {
                        delete this.trackedErrors[input1.name];
                    }
                }
                // If the expected type is "string"
                if (input1.type === 'string') {
                    if (typeof value1 !== 'string') {
                        this.trackedErrors[input1.name] = [
                            'Value must be a string'
                        ];
                    } else {
                        delete this.trackedErrors[input1.name];
                    }
                }
            }
        }
    }
    @action
    checkForCustomInputErrors() {
        for (const input1 of this.customInputs){
            // check if required
            if (input1.required) {
                if (input1.values.length === 0) {
                    this.trackedErrors[input1.label] = [
                        'This field is required'
                    ];
                } else {
                    delete this.trackedErrors[input1.label];
                }
            }
        }
    }
    @action
    noErrors(): boolean {
        for(const key1 in this.trackedErrors){
            if (Object.prototype.hasOwnProperty.call(this.trackedErrors, key1)) {
                if (this.trackedErrors[key1]?.length !== 0) {
                    return false;
                }
            }
        }
        return true;
    }
    @action
    async addEducation() {
        this.checkForErrors();
        this.checkForCustomInputErrors();
        if (this.noErrors()) {
            try {
                if (this.args.course!.hasDirtyAttributes) {
                    await this.args.course!.save();
                }
                this.inputs = [];
                this.args.onClose();
            } catch (error1) {
                console.error(error1);
            }
        }
    }
    @action
    getValue(values1: (string | number)[]): string {
        return values1[0]?.toString() || '';
    }
    @action
    setValue(input1: BuiltInput, value1: string | number) {
        if (input1.type === 'currency') {
            value1 = currencyStringToNumber(value1 as string);
        }
        if (input1.type === 'number') {
            // course credits can be halves ex: 2.5
            value1 = parseFloat(value1 as string);
            if (isNaN(value1)) {
                this.trackedErrors[input1.name] = [
                    'This field must be a number'
                ];
            } else {
                delete this.trackedErrors[input1.name];
            }
        }
        input1.values.push(value1);
        setSingleValueForTasField(input1.name, value1, this.courseFields);
    }
    static{
        template(`
    <Drawer @isOpen={{@isOpen}} @onClose={{@onClose}} @allowCloseButton={{false}}>
      <div class="p-6 bg-gray-50">
        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button type="button" {{on "click" @onClose}}>
            <XMark class="h-6 w-6" />
          </button>
        </div>
        <h2 class="text-gray-600 font-semibold">
          {{t "add_education_drawer.add_education_information"}}</h2>
        <p class="text-gray-400 text-md">{{t "add_education_drawer.fill_out_education_below"}}</p>
      </div>
      <div class="flex py-4 px-6">
        <div class="my-4">
          <h1 class="text-grey-600 text-2xl">{{or
              @application.expenseType
              (t "add_education_drawer.education_information")
            }}</h1>
          <p>
            <span class="text-error-400 text-xl">*</span>
            {{t "add_education_drawer.required_fields"}}</p>
        </div>
      </div>
      {{#each this.inputs as |input|}}
        {{#if input.visible}}
          <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
            <label for={{input.id}} class="font-semibold text-gray-600">
              {{input.label}}
              {{#if input.required}}
                <span class="text-error-400">*</span>
              {{/if}}
            </label>
            {{! regular input with type currency }}
            {{#if (and (eq input.inputType "input") (eq input.type "currency"))}}
              <Input
                id={{input.id}}
                @classes={{this.inputClasses}}
                @value="{{divide (or (this.getValue input.values) 0) 100}}"
                @onChange={{fn this.setValue input}}
                {{inputmask
                  alias="currency"
                  prefix="\$"
                  unmaskAsNumber=true
                  digits="2"
                  autoUnmask=true
                }}
                @errors={{this.getErrorForInput input this.trackedErrors}}
                data-test-education-drawer-input-currency
              />
            {{/if}}
            {{! regular input with type string }}
            {{#if (and (eq input.inputType "input") (eq input.type "string"))}}
              <Input
                id={{input.id}}
                @classes={{this.inputClasses}}
                @value={{this.getValue input.values}}
                @onChange={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
                data-test-education-drawer-input-string
              />
            {{/if}}
            {{! regular input with type number }}
            {{#if (and (eq input.inputType "input") (eq input.type "number"))}}
              <Input
                id={{input.id}}
                @classes={{this.inputClasses}}
                @value={{this.getValue input.values}}
                @onChange={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
                data-test-education-drawer-input-number
              />
            {{/if}}
            {{#if (eq input.inputType "textarea")}}
              <Textarea
                @id={{input.id}}
                @classes={{this.inputClasses}}
                @value={{this.getValue input.values}}
                @onChange={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
                data-test-education-drawer-textarea
              />
            {{/if}}
            {{#if (eq input.inputType "radio")}}
              <FormRadioGroup
                @value={{this.getValue input.values}}
                {{! @glint-expect-error }}
                @onChange={{fn this.setValue input}}
                {{!-- @errors={{errorsForField "employmentStatus" schemaErrors=this.formValidationErrors}} --}}
                @containerClass="py-4"
                as |Radio|
              >
                {{#each input.options as |option|}}
                  {{! @glint-expect-error }}
                  <Radio @value={{option.value}} @label={{option.label}} />
                {{/each}}
              </FormRadioGroup>
            {{/if}}
            {{#if (eq input.inputType "select")}}
              <Select
                @id={{input.id}}
                @classes={{this.inputClasses}}
                @items={{input.options}}
                @selectionMode={{input.selectionMode}}
                @onAction={{fn this.setValue input}}
                @errors={{this.getErrorForInput input this.trackedErrors}}
                data-test-education-drawer-select
              >
                <:item as |o|>
                  <o.Item @key={{o.item.value}} @intent="default" @appearance="faded">
                    {{o.item.value}}
                  </o.Item>
                </:item>
              </Select>
            {{/if}}
          </div>
        {{/if}}
      {{/each}}
      {{#each this.customInputs as |customInput|}}
        {{#if customInput.visible}}
          <div class="flex flex-col py-4 px-6 w-full border-b border-gray-200">
            <label class="font-semibold text-gray-600">
              {{customInput.label}}
              {{#if customInput.required}}
                <span class="text-error-400">*</span>
              {{/if}}
            </label>
            {{#if (eq customInput.input "textarea")}}
              <Textarea
                @classes={{this.inputClasses}}
                {{! @glint-expect-error }}
                @value={{this.getValue customInput.values}}
                {{! @glint-expect-error }}
                @onChange={{fn this.setValue customInput}}
                @errors={{this.getErrorForInput customInput this.trackedErrors}}
                data-test-education-drawer-textarea
              />
            {{/if}}
            {{#if (eq customInput.input "radio")}}
              <FormRadioGroup
                {{! @glint-expect-error }}
                @value={{this.getValue customInput.values}}
                {{! @glint-expect-error }}
                @onChange={{fn this.setValue customInput}}
                @errors={{this.getErrorForInput customInput this.trackedErrors}}
                @containerClass="py-4"
                as |Radio|
              >
                {{#each customInput.options as |option|}}
                  {{! @glint-expect-error }}
                  <Radio @value={{option.value}} @label={{option.label}} />
                {{/each}}
              </FormRadioGroup>
            {{/if}}
          </div>
        {{/if}}
      {{/each}}
      <div class="mt-8 flex justify-end">
        <button
          type="button"
          class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-gray-800 focus:outline-none bg-white rounded-lg hover:bg-gray-50 hover:border hover:border-black"
          {{on "click" @onClose}}
        >
          {{t "common.cancel"}}
        </button>
        <button
          type="submit"
          class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-800"
          {{on "click" this.addEducation}}
        >
          {{t "common.add"}}
        </button>
      </div>
    </Drawer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
