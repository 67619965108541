import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { Button } from 'tio-ui/components/buttons';
import { currencyStringToNumber } from 'tio-common/utils/format';
import { Divider } from 'tio-ui/components/utilities';
import { array, fn } from '@ember/helper';
import { Input, Select } from 'tio-ui/components/forms';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { or } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked, cached } from '@glimmer/tracking';
import { getAllValuesForTasField, getConfigForTasField, getSingleValueForTasField, getSortedOptionsByFieldName, setSingleValueForTasField, type FieldValue } from 'tio-common/utils/tuition-assistance/fields';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import ActionableList from 'tio-ui/components/actionable-list';
import AddEducationDrawer from 'tio-employee/components/tas/add-education-drawer';
import AddScholarshipsDrawer from 'tio-employee/components/tas/add-scholarships-drawer';
import AppPage from 'tio-ui/components/app-page';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import CheckCircleSolid from 'ember-static-heroicons/components/solid-24/check-circle';
import ClipboardDocumentList from 'ember-static-heroicons/components/outline-24/clipboard-document-list';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import DrawerTarget from 'tio-ui/components/drawer-target';
import InlineEdit from 'tio-ui/components/inline-edit';
import inputmask from 'tio-common/modifiers/inputmask';
import LimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import MissingRequirements from 'tio-employee/components/tas/missing-requirements';
import PencilSquare from 'ember-static-heroicons/components/outline-24/pencil-square';
import Plus from 'ember-static-heroicons/components/solid-24/plus';
import ReimbursementCalculationIfApplicable from 'tio-common/components/tuition-assistance/reimbursement-calculation-if-applicable';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import TasCardGeneric from 'tio-ui/components/card-generic';
import TasPageHeader from 'tio-employee/components/tas/page-header';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import XCircle from 'ember-static-heroicons/components/outline-24/x-circle';
import type { ReceivedScholarship } from 'tio-common/types/tuition-assistance';
import type { TOC } from '@ember/component/template-only';
import type ConditionModel from 'tio-common/models/condition';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasCourseModel from 'tio-common/models/tas-course';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
interface HeaderSignature {
    Args: {
        title: string;
        required?: boolean;
        subtitle?: string;
        subHeader?: string;
    };
    Element: HTMLDivElement;
}
const Header: TOC<HeaderSignature> = template(`
  <div class="my-4" ...attributes>
    <h1 class="text-grey-600 text-2xl">{{@title}}</h1>
    <p>
      {{#if @required}}
        <span class="text-error-400 text-xl">*</span>
      {{/if}}
      {{@subtitle}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Header2: TOC<HeaderSignature> = template(`
  <div ...attributes>
    <h1 class="text-grey-600 text-2xl">{{@title}}
      {{#if @required}}
        <span class="text-error-400 text-2xl">*</span>
      {{/if}}
      {{#if @subHeader}}
        <span class="text-grey-400 text-lg">{{@subHeader}}</span>
      {{/if}}
    </h1>
    <p>{{@subtitle}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface NewTypeTwoOrThreeSignature {
    Args: {
        model: {
            application: TasApplicationModel;
            instance: TASProgramInstanceModel;
        };
    };
}
interface Scholarship extends ReceivedScholarship {
    id?: string;
}
export default class NewTypeTwoOrThreeComponent extends Component<NewTypeTwoOrThreeSignature> {
    programTemplate: TasProgramTemplateModel;
    constructor(owner1: unknown, args1: NewTypeTwoOrThreeSignature['Args']){
        super(owner1, args1);
        const { application: application1 } = this.args.model;
        this.programTemplate = application1.tasProgramInstance.tasProgramTemplate;
        this.scholarshipToDelete = null;
        this.scholarshipToEdit = null;
        this.expenseType = application1.expenseType || 'Education';
    }
    @tracked
    expenseType = 'Education';
    @tracked
    isEducationOpen = false;
    @tracked
    isSchorlarshipOpen = false;
    @tracked
    isDeleteApplicationOpen = false;
    @tracked
    isDeleteScholarshipOpen = false;
    @tracked
    isOpen = false;
    @tracked
    isEditScholarshipOpen = false;
    @tracked
    scholarshipEditError = '';
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @tracked
    scholarshipToDelete: Scholarship | null;
    @tracked
    scholarshipToEdit: Scholarship | null;
    @tracked
    isDeleteCourseOpen = false;
    @tracked
    course: TasCourseModel | null = null;
    @tracked
    submitError = null;
    @tracked
    hasSubmitted = false;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    router: RouterService;
    get application() {
        return this.args.model.application;
    }
    get applicationFields(): TasApplicationModel['fields'] {
        return this.application.fields;
    }
    get applicationName() {
        return getSingleValueForTasField('APPLICATION_NAME', this.applicationFields);
    }
    get educationSectionTitle() {
        return this.application.expenseType || 'Education';
    }
    get typeClassification() {
        return this.programTemplate.typeClassification || '';
    }
    get conditionsForSubmit() {
        const conditionsMap1 = {
            'TAS.ProgramType.2': this.programTemplate.typeTwoApplicationConditions,
            'TAS.ProgramType.3': this.programTemplate.applicationApproveCoursesConditions
        };
        return conditionsMap1[this.typeClassification as keyof typeof conditionsMap] || [];
    }
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    saveAgreementsForConditions = dropTask(async ()=>{
        const user1 = this.sessionContext.user;
        const agreements1 = this.acceptedConditions.map((condition1)=>{
            return this.store.createRecord('agreement', {
                user: user1,
                condition: condition1
            });
        });
        const promises1 = agreements1.map((agreement1)=>{
            return agreement1.save();
        });
        const saved1 = await all(promises1);
        return saved1;
    });
    get requireAttachmentUploads() {
        if (this.programTemplate.isCertificateProgram) {
            return (this.programTemplate.requireAttachmentsForCourseCompletion || this.programTemplate.isEvidenceRequired);
        }
        return this.programTemplate.isEvidenceRequired;
    }
    get attachmentClassificationString() {
        return this.typeClassification === 'TAS.ProgramType.2' ? 'COMPLETION_DOCUMENTS' : 'COURSE_APPROVAL_DOCUMENTS';
    }
    get attachmentsList() {
        return (getAllValuesForTasField(`${this.attachmentClassificationString}_ATTACHMENT_FIELD`, this.programTemplate.fields).join(', ') || 'None Required');
    }
    @cached
    get expenseTypeFieldConfig() {
        return getConfigForTasField('EXPENSE_TYPE', this.programTemplate.fields);
    }
    get coursesAdded() {
        return !!this.application?.tasCourses?.length || false;
    }
    get scholarshipsAdded() {
        return !!this.application?.scholarships?.length || false;
    }
    get scholarships() {
        let savedScholarships1 = getAllValuesForTasField('SCHOLARSHIPS_RECEIVED', this.application.fields);
        return savedScholarships1.map((scholarship1, index1)=>({
                ...(scholarship1 as ReceivedScholarship),
                id: `scholarship-${index1 + 1}`
            }));
    }
    @action
    didUpdateAcceptedConditions(conditions1: ConditionModel[] = []) {
        this.acceptedConditions = conditions1;
    }
    @action
    saveStartDate(value1: string) {
        setSingleValueForTasField('COURSES_BEGIN_DATE', value1, this.applicationFields);
    }
    @action
    updateAppName(value1: string) {
        setSingleValueForTasField('APPLICATION_NAME', value1, this.applicationFields);
    }
    @action
    updateExpenseType(value1: string) {
        this.expenseType = value1;
        setSingleValueForTasField('EXPENSE_TYPE', value1, this.applicationFields);
    }
    @action
    openEducationDrawer(course1?: TasCourseModel | null) {
        if (course1) {
            this.course = course1;
        } else {
            this.course = this.store.createRecord('tas-course', {
                tasApplication: this.application,
                fields: this.programTemplate.courseFieldsHash,
                customFields: this.programTemplate.courseCustomFields
            });
        }
        this.isEducationOpen = true;
    }
    @action
    closeEducationDrawer() {
        this.course = null;
        this.isEducationOpen = false;
    }
    @action
    toggleScholarshipDrawer() {
        this.isSchorlarshipOpen = !this.isSchorlarshipOpen;
    }
    get canSubmitApplication() {
        const stateToApplyMap1 = {
            'TAS.ProgramType.2': 'TAS.ApplicationState.ATTEND',
            'TAS.ProgramType.3': 'TAS.ApplicationState.DEFINE_COURSES'
        };
        const requiredState1 = stateToApplyMap1[this.typeClassification as keyof typeof stateToApplyMap];
        return (this.hasAgreedAllConditions && this.application.hasRequiredEvidence && this.application.state === requiredState1);
    }
    get adapterMethod() {
        const actionMap1 = {
            'TAS.ProgramType.2': 'requestCourseEvidence',
            'TAS.ProgramType.3': 'requestCoursesApproval'
        };
        return actionMap1[this.typeClassification as keyof typeof actionMap] || '';
    }
    @action
    submitApplication() {
        if (!this.canSubmitApplication) {
            return;
        }
        this.onSave();
        this.submitTask.perform();
    }
    submitTask = dropTask(async ()=>{
        try {
            this.hasSubmitted = true;
            this.submitError = null;
            await this.store.adapterFor('tas-application')[this.adapterMethod](this.application);
            this.router.transitionTo('authenticated.tas.applications.show', this.application.id, {
                queryParams: {
                    applicationSubmitted: true
                }
            });
        } catch (error1) {
            console.error('Failed to submit application:', error1);
            this.submitError = error1;
        }
    });
    @action
    async onSave() {
        try {
            if (this.application.hasDirtyAttributes) {
                await this.application.save();
            }
        } catch (error1) {
            console.error('Failed to save courses or application:', error1);
        }
    }
    @action
    async onFinishLater() {
        try {
            if (this.application.hasDirtyAttributes) {
                // Save the application if it has unsaved changes
                await this.application.save();
            }
            // TAS.TODO: temporary routing until tas dashboard is complete
            this.router.transitionTo('authenticated.tuition-assistance.dashboard');
        } catch (error1) {
            console.error('Failed to save application:', error1);
        }
    }
    @action
    onTrash() {
        this.isDeleteApplicationOpen = true;
    }
    @action
    toggleDeleteApplication() {
        this.isDeleteApplicationOpen = !this.isDeleteApplicationOpen;
    }
    @action
    toggleDeleteCourse(course1: TasCourseModel | null) {
        this.isDeleteCourseOpen = !this.isDeleteCourseOpen;
        if (course1) {
            this.course = course1;
        }
    }
    deleteApplication = dropTask(async ()=>{
        const adapter1 = this.store.adapterFor('tas-application');
        try {
            await adapter1.abandonCourse(this.application);
            // TAS.TODO: temporary routing until tas dashboard is complete
            this.router.transitionTo('authenticated.tuition-assistance.dashboard');
        } catch (e1) {
            console.error(e1);
        }
    });
    deleteCourse = dropTask(async ()=>{
        if (!this.course) {
            return;
        }
        try {
            if (this.course.isNew) {
                await this.course.rollbackAttributes();
            } else {
                await this.course.destroyRecord();
            }
            this.isDeleteCourseOpen = false;
            this.course = null;
        } catch (e1) {
            console.error(e1);
        }
    });
    @action
    openDeleteScholarship(scholarship1: ReceivedScholarship) {
        this.scholarshipToDelete = scholarship1;
        this.isDeleteScholarshipOpen = true;
    }
    @action
    closeDeleteScholarship() {
        this.scholarshipToDelete = null;
        this.isDeleteScholarshipOpen = false;
    }
    @action
    deleteScholarship() {
        let existingScholarships1 = getAllValuesForTasField('SCHOLARSHIPS_RECEIVED', this.args.model.application.fields);
        let updatedScholarships1 = existingScholarships1.filter((scholarship1: FieldValue)=>(scholarship1 as Record<string, unknown>)?.scholarshipName !== this.scholarshipToDelete?.scholarshipName) as ReceivedScholarship[];
        this.applicationFields['SCHOLARSHIPS_RECEIVED'] = {
            values: updatedScholarships1.filter((scholarship1)=>scholarship1 !== null)
        };
        this.args.model.application.fields = this.applicationFields;
        this.args.model.application.save();
        this.isDeleteScholarshipOpen = false;
    }
    @action
    openEditScholarship(scholarship1: ReceivedScholarship) {
        this.scholarshipToEdit = scholarship1;
        this.isEditScholarshipOpen = true;
    }
    @action
    closeEditScholarship() {
        this.scholarshipToEdit = null;
        this.isEditScholarshipOpen = false;
    }
    @action
    updateName(value1: string) {
        this.scholarshipToEdit!.scholarshipName = value1;
    }
    @action
    updateAmount(value1: string) {
        this.scholarshipToEdit!.scholarshipAmount = currencyStringToNumber(value1);
    }
    @action
    async checkForMissingFields() {
        if (this.scholarshipToEdit?.scholarshipName === '' || this.scholarshipToEdit?.scholarshipAmount === 0) {
            this.scholarshipEditError = 'A required field is missing';
        } else {
            this.scholarshipEditError = '';
        }
    }
    @action
    async updateScholarship() {
        await this.checkForMissingFields();
        if (this.scholarshipEditError !== '') {
            return;
        }
        const updatedScholarships1 = this.scholarships.map((scholarship1)=>scholarship1.id === this.scholarshipToEdit?.id ? {
                scholarshipName: this.scholarshipToEdit.scholarshipName,
                scholarshipAmount: this.scholarshipToEdit.scholarshipAmount
            } : scholarship1);
        this.args.model.application.fields['SCHOLARSHIPS_RECEIVED'] = {
            values: updatedScholarships1
        };
        this.args.model.application.save();
        this.scholarshipToEdit = null;
        this.closeEditScholarship();
    }
    inputClasses = {
        base: 'w-full mb-6'
    };
    static{
        template(`
    <AppPage>
      <:title>
        <div class="mr-6">
          <TasPageHeader>
            <:title as |title|>
              {{! TAS.TODO: temporary routing until tas dashboard is complete }}
              <title.BackLink @label="Back" @route="authenticated.tuition-assistance.dashboard">
                {{t "sidebar.tuition_assistance"}}
              </title.BackLink>
            </:title>
            <:actions as |actions|>
              <actions.Button {{on "click" this.onSave}}>{{t "save"}}</actions.Button>
              <actions.Button {{on "click" this.onFinishLater}}>
                {{t "application.type_two.new.finish_later"}}
              </actions.Button>
              <actions.IconButton {{on "click" this.onTrash}} @type="trash" />
            </:actions>
          </TasPageHeader>
        </div>
      </:title>
      <:content>
        <DrawerTarget />
        <div class="flex flex-wrap lg:flex-nowrap gap-x-4 w-full">
          <div class="flex flex-col w-full">
            <Header @title="Application" @required={{true}} @subtitle="Required Items" />
            <section class="lg:w-3/4">
              <div class="flex items-center w-full py-4">
                <div class="w-1/2">
                  <h2 class="text-grey-600 font-semibold">{{t
                      "application.type_two.new.start_date"
                    }}</h2>
                </div>
                <div class="w-1/2">
                  <InlineEdit
                    @value="10/31/2024"
                    @onSave={{this.saveStartDate}}
                    @inputType="date"
                  />
                </div>
              </div>
              <Divider class="my-2" />
              <div class="flex items-center w-full py-4">
                <div class="w-1/2">
                  <h2 class="text-grey-600 font-semibold">{{t "application.type_two.new.name"}}
                    <span class="text-error-400 text-xl">*</span>
                  </h2>
                </div>
                <div class="w-1/2">
                  <label for="application-name" class="sr-only">
                    {{t "application.type_two.new.name"}}!!
                  </label>
                  <Input
                    size="lg"
                    id="application-name"
                    @value={{getSingleValueForTasField
                      "APPLICATION_NAME"
                      @model.application.fields
                    }}
                    class="!bg-transparent"
                    @onChange={{this.updateAppName}}
                  />
                </div>
              </div>
              {{#let this.expenseTypeFieldConfig as |config|}}
                {{#if config.visible}}
                  <div class="flex items-center w-full py-4">
                    <div class="w-1/2">
                      <h2 class="text-grey-600 font-semibold">
                        {{t "tas.new.expense_type"}}
                        {{#if config.required}}
                          <span class="text-error-400 text-xl">*</span>
                        {{/if}}
                      </h2>
                    </div>
                    <div class="w-1/2">
                      <label for="expense-type" class="sr-only">
                        {{t "tas.new.expense_type"}}
                      </label>
                      <Select
                        id="expense-type"
                        @classes={{this.inputClasses}}
                        @items={{getSortedOptionsByFieldName
                          "EXPENSE_TYPE"
                          this.programTemplate.tasFieldOptions
                        }}
                        @selectionMode="single"
                        @onAction={{this.updateExpenseType}}
                        @selectedKeys={{array
                          (getSingleValueForTasField "EXPENSE_TYPE" @model.application.fields)
                        }}
                      >
                        <:item as |o|>
                          <o.Item @key={{o.item.value}}>
                            {{o.item.value}}
                          </o.Item>
                        </:item>
                      </Select>
                    </div>
                  </div>
                {{/if}}
              {{/let}}
              <Divider class="my-2" />
            </section>
            {{! Add Education}}
            <section>
              <div
                class="mt-20 flex gap-4 justify-between
                  {{if this.coursesAdded 'flex-row' 'flex-col'}}"
              >
                <Header2
                  @title={{this.expenseType}}
                  @subtitle={{if this.coursesAdded "" "Add Expense for reimbursement"}}
                  @required={{true}}
                  class={{if this.coursesAdded "self-end"}}
                />
                <div>
                  <button
                    type="button"
                    class="flex py-3 px-6 me-2 mb-2 font-medium text-lg text-ocean-600 focus:outline-none bg-white rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-700"
                    {{on "click" (fn this.openEducationDrawer null)}}
                  >
                    <Plus class="text-ocean-600 h-6 w-6 mr-2" />
                    {{or this.expenseType (t "application.type_two.new.education")}}
                  </button>
                </div>
              </div>
              <Divider class="my-2" />
              <ActionableList @striped={{false}} as |l|>
                {{#each @model.application.activeCourses as |course|}}
                  <l.Row>
                    <l.Term class="md:w-1/3">{{course.courseName}}</l.Term>
                    <l.Details>
                      <l.Item class="justify-self-center md:justify-self-start">
                        {{safeFormatNumber
                          (divide course.courseTotal 100)
                          style="currency"
                          currency="USD"
                        }}
                      </l.Item>
                      <l.Item
                        class="justify-self-center md:justify-self-start font-semibold"
                      >{{course.courseGrade}}</l.Item>
                    </l.Details>
                    <l.Action>
                      <button
                        type="button"
                        {{on "click" (fn this.toggleDeleteCourse course)}}
                      ><XCircle class="h-6 w-6" /></button>
                      <button
                        type="button"
                        {{on "click" (fn this.openEducationDrawer course)}}
                      ><PencilSquare class="h-6 w-6" /></button>
                    </l.Action>
                  </l.Row>
                {{/each}}
              </ActionableList>
            </section>
            {{! Add Scholarships }}
            <section>
              <div
                class="mt-20 flex gap-4 justify-between
                  {{if this.scholarshipsAdded 'flex-row' 'flex-col'}}"
              >
                <Header2
                  @title={{t "tas.new.scholarships.default"}}
                  @subHeader={{t "tas.new.scholarships.optional"}}
                  @subtitle={{if
                    this.scholarshipsAdded
                    ""
                    (t "tas.new.scholarships.please_enter_any_scholarships")
                  }}
                  class={{if this.scholarshipsAdded "self-end"}}
                />
                <div>
                  <button
                    type="button"
                    class="flex py-3 px-6 me-2 mb-2 font-medium text-lg text-ocean-600 focus:outline-none bg-white rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-700"
                    {{on "click" this.toggleScholarshipDrawer}}
                  >
                    <Plus class="text-ocean-600 h-6 w-6 mr-2" />
                    {{t "tas.new.scholarships.default"}}
                  </button>
                </div>
              </div>
              <ActionableList @striped={{false}} as |l|>
                {{#each this.scholarships as |scholarship|}}
                  <l.Row>
                    <l.Term>{{scholarship.scholarshipName}}</l.Term>
                    <l.Details>
                      <l.Item class="justify-self-center">
                        {{safeFormatNumber
                          (divide scholarship.scholarshipAmount 100)
                          style="currency"
                          currency="USD"
                        }}
                      </l.Item>
                    </l.Details>
                    <l.Action>
                      <button type="button">
                        <XCircle
                          class="h-6 w-6 text-gray-700"
                          {{on "click" (fn this.openDeleteScholarship scholarship)}}
                        />
                      </button>
                      <button type="button">
                        <PencilSquare
                          class="h-6 w-6 text-gray-700"
                          {{on "click" (fn this.openEditScholarship scholarship)}}
                        />
                      </button>
                    </l.Action>
                  </l.Row>
                {{/each}}
              </ActionableList>
            </section>

            <Divider class="my-2" />
            {{! Upload Attachments}}
            <section>
              <div class="mt-20">
                <Header2
                  @title="Attachments"
                  @required={{this.requireAttachmentUploads}}
                  @subtitle="Upload documents as required by your program administrator."
                />
              </div>
              <div class="flex items-center w-full md:max-w-lg mb-4">
                <UploadAttachments
                  @programTemplate={{this.programTemplate}}
                  @classification={{this.attachmentClassificationString}}
                  @attachable={{@model.application}}
                  @attachableType="tasApplication"
                  @useDropzone={{true}}
                  {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                />
              </div>
            </section>
            {{#if this.isEducationOpen}}
              <AddEducationDrawer
                @isOpen={{this.isEducationOpen}}
                @onClose={{this.closeEducationDrawer}}
                @application={{@model.application}}
                @course={{this.course}}
              />
            {{/if}}
            <AddScholarshipsDrawer
              @isOpen={{this.isSchorlarshipOpen}}
              @onClose={{this.toggleScholarshipDrawer}}
              @application={{@model.application}}
            />
          </div>

          {{! Benefits Summary Window}}
          <section class="sm:mx-4 lg:mx-8 md:flex flex-col">
            <div class="flex items-center mt-4">
              <TasCardGeneric>
                <:header>
                  <h2>{{t "benefits_summary.title"}}</h2>
                </:header>
                <:body>
                  <LimitsSummary
                    @application={{@model.application}}
                    @employee={{@model.application.tasProgramInstance.employee}}
                    @labelClass="col-span-5"
                    @valueClass="col-span-2 text-violet-800"
                    class="my-4"
                    as |Summary|
                  >
                    <div class="w-full grid grid-cols-7 gap-2 mt-3">
                      {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
                        <Summary.MaxAnnualBenefitLineItem />
                      {{/unless}}

                      {{#if Summary.hasLimitsErrorForEmployee}}
                        <Summary.RemainingBenefitLineItem />
                      {{/if}}
                      <Summary.ApplicationAmountRequestedWithoutScholarshipsLineItem
                        @valueClass="text-tio-gray-800 col-span-2"
                      />
                      {{#if this.programTemplate.scholarshipsAreVisible}}
                        <Summary.ScholarshipAmountLineItem
                          @valueClass="text-tio-gray-800 !font-normal col-span-2"
                        />
                      {{/if}}
                      <Summary.LimitsAlertForEmployeeIfApplicable class="text-sm col-span-full" />
                      <ReimbursementCalculationIfApplicable @application={{@model.application}} />
                      <Divider class="my-2 col-span-7" />
                      <Summary.ApplicationAmountRequestedLineItem />
                    </div>
                    {{! Required Items Section }}
                    <section class="my-5">
                      <header
                        class="border-y bg-tio-gray-25 flex items-center gap-2 py-2 px-5 text-sm font-medium tracking-wide -mx-6"
                      >
                        <ClipboardDocumentList class="h-8 w-8" />
                        <span>{{t "benefits_summary.required_items"}}</span>
                      </header>

                      <div>
                        <div class="flex flex-row justify-between items-center">
                          <p>{{t "benefits_summary.attachments"}}
                            <span class="text-error-400 ml-1">
                              {{#if this.requireAttachmentUploads}}*{{/if}}
                            </span>
                          </p>
                          <div>
                            <UploadAttachments
                              @programTemplate={{this.programTemplate}}
                              @classification={{this.attachmentClassificationString}}
                              @attachable={{@model.application}}
                              @attachableType="tasApplication"
                              @useDropzone={{false}}
                              @iconOnlyUpload={{true}}
                              @hideAttachments={{true}}
                              @hideInstructions={{true}}
                              {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                            />
                          </div>
                        </div>
                        <div class="flex items-center gap-3 -mt-1">
                          {{#if @model.application.isMissingRequiredEvidence}}
                            <CheckCircle class="h-6 w-6 text-tio-gray-200" />
                          {{else}}
                            <CheckCircleSolid class="h-6 w-6 text-violet-500" />
                          {{/if}}
                          <p>{{this.attachmentsList}}</p>
                        </div>
                      </div>

                      {{#if this.conditionsForSubmit.length}}
                        <AcceptConditions
                          @conditions={{this.conditionsForSubmit}}
                          @onChange={{this.didUpdateAcceptedConditions}}
                        />
                      {{/if}}
                    </section>
                    <div class="py-4">
                      {{#if this.canSubmitApplication}}
                        <Button
                          class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg hover:bg-ocean-400"
                          {{on "click" this.submitApplication}}
                        >
                          {{t "benefits_summary.submit_application"}}
                        </Button>
                      {{else}}
                        <MissingRequirements />
                      {{/if}}
                      <p class="text-sm font-light text-center">
                        {{t "benefits_summary.helper_text"}}
                      </p>
                      {{#if this.submitError}}
                        <TioErrorMessages
                          @showErrors={{true}}
                          @error={{this.submitError}}
                          class="text-sm"
                          @icon="warning"
                        />
                      {{/if}}
                    </div>
                  </LimitsSummary>
                </:body>
              </TasCardGeneric>
            </div>
          </section>
        </div>
      </:content>
    </AppPage>

    {{! Delete Application Modal }}
    <Modal
      @isOpen={{this.isDeleteApplicationOpen}}
      @onClose={{this.toggleDeleteApplication}}
      as |m|
    >
      <m.Header>{{t "application.type_two.new.delete_application"}}</m.Header>
      <m.Body>
        <p>{{t "application.type_two.new.confirm_application_delete"}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.deleteApplication.isRunning}}
          @onCancel={{this.toggleDeleteApplication}}
          @onSubmit={{this.deleteApplication.perform}}
        />
      </m.Footer>
    </Modal>

    <Modal @isOpen={{this.isDeleteCourseOpen}} @onClose={{fn this.toggleDeleteCourse null}} as |m|>
      <m.Header>{{t "application.type_two.new.delete_course"}}</m.Header>
      <m.Body>
        <p>{{t "application.type_two.new.confirm_delete_expense"}}:</p>
        <p class="font-semibold px-2">{{this.course.courseName}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.deleteCourse.isRunning}}
          @onCancel={{fn this.toggleDeleteCourse null}}
          @onSubmit={{this.deleteCourse.perform}}
        />
      </m.Footer>
    </Modal>
    {{! Delete Scholarship Modal }}
    <Modal @isOpen={{this.isDeleteScholarshipOpen}} @onClose={{this.closeDeleteScholarship}} as |m|>
      <m.Header>{{t "application.type_two.new.delete_scholarship"}}</m.Header>
      {{#let this.scholarshipToDelete as |scholarship|}}
        <m.Body>
          <p>{{t "application.type_two.new.confirm_scholarship_delete"}}</p>
          <div class="p-8 flex w-2/3 justify-between">
            <p class="font-medium">{{scholarship.scholarshipName}}</p>
            <p class="font-medium">{{safeFormatNumber
                (divide scholarship.scholarshipAmount 100)
                style="currency"
                currency="USD"
              }}</p>
          </div>
        </m.Body>
      {{/let}}
      <m.Footer>
        <Button @appearance="minimal" class="mr-4" {{on "click" this.closeDeleteScholarship}}>
          {{t "cancel"}}
        </Button>
        <Button @intent="danger" {{on "click" this.deleteScholarship}}>
          {{t "delete"}}
        </Button>
      </m.Footer>
    </Modal>
    {{! Edit Scholarship Modal }}
    <Modal @isOpen={{this.isEditScholarshipOpen}} @onClose={{this.closeEditScholarship}} as |m|>
      <m.Header>{{t "application.type_two.new.edit_scholarship"}}</m.Header>
      <m.Body>
        <div class="flex flex-col w-full">
          <label for="scholarship_name4" class="font-semibold text-gray-600">
            {{t "add_scholarships_drawer.scholarship_name"}}
            <span class="text-error-400">*</span>
          </label>
          <Input
            @value={{this.scholarshipToEdit.scholarshipName}}
            @classes={{this.inputClasses}}
            @onChange={{this.updateName}}
            @isRequired={{true}}
          />
          <label for="scholarship_amount4" class="font-semibold text-gray-600">
            {{t "add_scholarships_drawer.scholarship_amount"}}
            <span class="text-error-400">*</span>
          </label>
          <Input
            @value="{{safeFormatNumber
              (divide this.scholarshipToEdit.scholarshipAmount 100)
              style="currency"
              currency="USD"
            }}"
            @classes={{this.inputClasses}}
            @onChange={{this.updateAmount}}
            @isRequired={{true}}
            {{inputmask alias="currency" prefix="\$" unmaskAsNumber=true digits="2"}}
          />
          <p class="text-danger">{{this.scholarshipEditError}}</p>
        </div>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onCancel={{this.closeEditScholarship}}
          @onSubmit={{this.updateScholarship}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
