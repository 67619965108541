import { service } from '@ember/service';
import { setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import Route from '@ember/routing/route';
import type { IntlService } from 'ember-intl';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TASCourseModel from 'tio-common/models/tas-course';
import type Owner from '@ember/owner';

export default class AuthenticatedTasApplicationsTypeTwoNewRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  // @service declare tuitionAssistance: TuitionAssistanceService;

  constructor(owner: Owner) {
    super(owner);

    // If a user is transitioning away without saving, delete any unsaved records from the store
    this.router.on('routeDidChange', (transition) => {
      // @ts-expect-error: this might be a bug. name doesnt appear on route.
      if (!transition.to.find((route: Route) => route.name === this.routeName)) {
        const courses = this.store.peekAll('tas-course') as TASCourseModel[];
        const unsaved = courses.filter((course) => course.isNew);
        unsaved.forEach((course) => {
          course.rollbackAttributes();
        });
      }
    });
  }

  includes = [
    'tas-courses',
    'tas-courses.tas-assets',
    'tas-program-instance',
    'tas-program-instance.tas-program-template',
    'tas-program-instance.tas-program-template.conditions.public-assets',
    'tas-program-instance.tas-program-template.tas-field-options',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'tas-assets',
    'tas-assistances',
    'tags',
  ];

  async model({ tas_application_id }: { tas_application_id: string }) {
    const application = await this.store.findRecord<TasApplicationModel>(
      'tas-application',
      tas_application_id,
      {
        include: this.includes.join(','),
        reload: true,
      }
    );
    // Will need to double check if loading of the tasParticipant is necessary
    // like we have to do in the other routes
    // await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();
    const instance = application.tasProgramInstance;

    // Set fields for application and instance if empty
    if (Object.keys(application.fields).length === 0) {
      const programTemplate = application.tasProgramInstance.tasProgramTemplate;
      application.fields = programTemplate.applicationFieldsHash;
      application.customFields = programTemplate.applicationCustomFields;

      //set fields on instance
      instance.setInitialEmployeeFields();

      if (instance.isDependentProgram) {
        instance.setInitialDependentFields();
      }

      const displayName = this.intl.formatDate(Date.now(), {
        month: 'long',
        year: 'numeric',
      });
      setSingleValueForTasField('APPLICATION_NAME', displayName, application.fields);

      try {
        await application.save();
        await instance.save();
      } catch (error) {
        console.error('Failed to save application, instance, or course:', error);
        application.rollbackAttributes();
        instance.rollbackAttributes();
      }
    }

    return { application: application, instance: instance, courses: application.tasCourses || [] };
  }
}
