import { template } from "@ember/template-compiler";
import { Input } from 'tio-ui/components/forms';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { currencyStringToNumber, generateRandomString } from 'tio-common/utils/format';
import { getAllValuesForTasField } from 'tio-common/utils/tuition-assistance/fields';
import Component from '@glimmer/component';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
import Drawer from 'tio-ui/components/drawer';
import inputmask from 'tio-common/modifiers/inputmask';
import type TasApplication from 'tio-common/models/tas-application';
import type { TASCourseModelFieldsSignature } from 'tio-common/models/tas-course';
import { TrackedObject } from 'tracked-built-ins';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type { ReceivedScholarship } from 'tio-common/types/tuition-assistance';
interface AddScholarshipsDrawerSignature {
    Args: {
        isOpen: boolean;
        application: TasApplication;
        onClose: () => void;
    };
    Element: HTMLDivElement;
}
export type Scholarship = {
    id?: string;
    scholarshipAmount: number;
    scholarshipName: string;
    error?: string;
};
export default class AddScholarshipsDrawerComponent extends Component<AddScholarshipsDrawerSignature> {
    constructor(owner1: unknown, args1: AddScholarshipsDrawerSignature['Args']){
        super(owner1, args1);
        this.applicationFieldsCopy = new TrackedObject(this.args.application.fields);
    }
    @tracked
    scholarships: Scholarship[] = [];
    applicationFieldsCopy: TasApplicationModel['fields'];
    inputClasses = {
        base: 'w-full mb-6'
    };
    getErrorForInput(id1: string = '', scholarships1: Scholarship[]): string {
        return scholarships1.find((scholarship1: Scholarship)=>scholarship1.id === id1)?.error ?? '';
    }
    @action
    addScholarship() {
        this.scholarships.push({
            scholarshipName: '',
            scholarshipAmount: 0,
            error: '',
            id: generateRandomString()
        });
        this.scholarships = [
            ...this.scholarships
        ];
    }
    @action
    removeScholarship(id1: string = '') {
        this.scholarships = this.scholarships.filter((scholarship1)=>scholarship1.id !== id1);
    }
    @action
    closeDrawer() {
        this.scholarships = [];
        this.args.onClose();
    }
    @action
    setName(index1: number, value1: string) {
        this.scholarships[index1]!.scholarshipName = value1;
    }
    @action
    setAmount(index1: number, value1: string) {
        this.scholarships[index1]!.scholarshipAmount = currencyStringToNumber(value1);
    }
    @action
    async checkForMissingFields() {
        this.scholarships.forEach((scholarship1)=>{
            if (scholarship1.scholarshipName === '' || scholarship1.scholarshipAmount === 0) {
                scholarship1.error = 'A required field is missing';
            } else {
                scholarship1.error = '';
            }
            this.scholarships = [
                ...this.scholarships
            ];
        });
    }
    @action
    async saveScholarships() {
        await this.checkForMissingFields();
        const hasErrors1 = this.scholarships.some((scholarship1)=>scholarship1.error !== '');
        if (hasErrors1) {
            return;
        }
        //save scholarships
        let existingScholarships1 = getAllValuesForTasField('SCHOLARSHIPS_RECEIVED' as keyof TASCourseModelFieldsSignature, this.args.application.fields as unknown as TASCourseModelFieldsSignature);
        for (const scholarship1 of this.scholarships){
            existingScholarships1.push({
                scholarshipName: scholarship1.scholarshipName,
                scholarshipAmount: scholarship1.scholarshipAmount
            });
        }
        this.applicationFieldsCopy['SCHOLARSHIPS_RECEIVED'] = {
            values: existingScholarships1.filter((scholarship1)=>scholarship1 !== null) as ReceivedScholarship[]
        };
        this.args.application.fields = this.applicationFieldsCopy;
        this.scholarships = [];
        this.args.application.save();
        this.args.onClose();
    }
    static{
        template(`
    <Drawer @isOpen={{@isOpen}} @onClose={{@onClose}} @allowCloseButton={{false}}>
      <div class="p-6 bg-gray-50">
        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button {{on "click" @onClose}} type="button">
            <XMark class="h-6 w-6" />
          </button>
        </div>
        <h2 class="text-grey-600 font-semibold">
          {{t "add_scholarships_drawer.add_scholarships"}}
        </h2>
        <p class="text-gray-400 text-md">
          {{t "add_scholarships_drawer.fill_out_scholarship_below"}}
        </p>
      </div>
      <div class="flex py-4 px-6">
        <div class="my-4">
          <h1 class="text-grey-600 text-2xl">
            {{t "add_scholarships_drawer.scholarship_information"}}
          </h1>
          <p>
            <span class="text-error-400 text-xl">*</span>
            {{t "add_education_drawer.required_fields"}}</p>
        </div>
      </div>
      {{#if this.scholarships}}
        {{#each this.scholarships as |scholarship index|}}
          <div class="px-6 flex w-full">
            <div class="flex flex-col w-full">
              <label for="scholarship_name4" class="font-semibold text-gray-600">
                {{t "add_scholarships_drawer.scholarship_name"}}
                <span class="text-error-400">*</span>
              </label>
              <Input
                id={{index}}
                @classes={{this.inputClasses}}
                @onChange={{fn this.setName index}}
                @isRequired={{true}}
              />
              <label for="scholarship_amount4" class="font-semibold text-gray-600">
                {{t "add_scholarships_drawer.scholarship_amount"}}
                <span class="text-error-400">*</span>
              </label>
              <Input
                id={{scholarship.scholarshipAmount}}
                @classes={{this.inputClasses}}
                @onChange={{fn this.setAmount index}}
                @isRequired={{true}}
                {{inputmask alias="currency" prefix="\$" unmaskAsNumber=true digits="2"}}
              />
              <p class="text-danger">{{this.getErrorForInput scholarship.id this.scholarships}}</p>
            </div>
            <button
              class="ml-8 flex"
              {{on "click" (fn this.removeScholarship scholarship.id)}}
              type="button"
            >
              <XMark class="w-4 h-4" />
            </button>
          </div>
          <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        {{/each}}
        <div class="flex w-full justify-between">
          <button
            class="flex mt-8 ml-4 py-3 px-4 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-800"
            {{on "click" this.addScholarship}}
            type="button"
          >
            {{t "add_scholarships_drawer.add_another_scholarship"}}
          </button>
          <div class="mt-8 flex">
            <button
              type="button"
              class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-gray-800 focus:outline-none bg-white rounded-lg hover:bg-gray-50 hover:border hover:border-black"
              {{on "click" this.closeDrawer}}
            >
              {{t "cancel"}}
            </button>
            <button
              type="submit"
              class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-800"
              {{on "click" this.saveScholarships}}
            >
              {{t "save"}}
            </button>
          </div>
        </div>
      {{else}}
        <div class="px-16">
          <h3 class="text-xlfont-semibold leading-6 text-gray-900">
            {{t "add_scholarships_drawer.no_scholarships_added"}}
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500">
              <p>{{t "add_scholarships_drawer.no_scholarships_added_info"}}</p>
            </div>
            <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
              <button
                type="button"
                class="inline-flex items-center rounded-md bg-ocean-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ocean-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                {{on "click" this.addScholarship}}
              >
                {{t "add_scholarships_drawer.add_scholarship"}}
              </button>
            </div>
          </div>
        </div>
      {{/if}}
    </Drawer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
