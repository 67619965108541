import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Divider } from 'tio-ui/components/utilities';
import { formatAsDollars, toLocaleDateString } from 'tio-common/utils/format';
import { Modal } from 'tio-ui/components/modal';
import { t } from 'ember-intl';
import { tasStateLabel, tasStateIntent } from 'tio-common/utils/tuition-assistance/state';
import ActionableList from 'tio-ui/components/actionable-list';
import Alert from 'tio-employee/components/tas/applications/alert';
import ApplicationProcess from 'tio-employee/components/tuition-assistance/application/application-process';
import AppPage from 'tio-ui/components/app-page';
import AttachmentList from 'tio-common/components/tuition-assistance/forms/upload-attachments/attachment-list';
import Badge from 'tio-ui/components/badge';
import Component from '@glimmer/component';
import CourseSubmitted from 'tio-employee/components/tas/applications/course-submitted';
import DescriptionList from 'tio-ui/components/description-list';
import divide from 'ember-math-helpers/helpers/div';
import NavTabs from 'tio-ui/components/nav-tabs';
import PaymentDetailsCard from 'tio-common/components/tas/payment-details-card';
import RouteTemplate from 'ember-route-template';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import Table from 'tio-common/components/table/index';
import TasApplicationsShowController from 'tio-employee/controllers/authenticated/tas/applications/show';
import TasPageHeader from 'tio-employee/components/tas/page-header';
import TioButton from 'tio-common/components/tio/button';
import type TasApplicationModel from 'tio-common/models/tas-application';
interface RouteSignature {
    Args: {
        model: TasApplicationModel;
        controller: TasApplicationsShowController;
    };
}
let AuthenticatedTasApplicationsShowComponent = class AuthenticatedTasApplicationsShowComponent extends Component<RouteSignature> {
    @action
    closeApplicationSubmittedModal() {
        this.args.controller.applicationSubmitted = false;
    }
    static{
        template(`
    <AppPage @overrideTitle={{true}}>
      <:title>
        <TasPageHeader>
          <:title as |title|>
            <title.BackLink @route="authenticated.tas.dashboard">
              {{t "tas.default"}}
            </title.BackLink>
          </:title>
        </TasPageHeader>
      </:title>
      <:content>
        <div class="grid sm:grid-cols-4 gap-4">
          <div class="sm:col-span-3">
            <h1 class="tio-h1">{{@model.applicationName}}</h1>
            <NavTabs class="mb-4" as |navtabs|>
              <navtabs.item @route="authenticated.tas.applications.show" @model={{@model}}>
                {{t "tas.applications.default"}}
              </navtabs.item>
              <navtabs.item @route="authenticated.tas.applications.history" @model={{@model}}>
                {{t "tas.history.default"}}
              </navtabs.item>
            </NavTabs>
            <section class="mb-12">
              <Alert @application={{@model}} />
              <h2
                class="font-medium text-tio-gray-700 my-4"
              >{{@model.tasProgramInstance.programName}}</h2>
              {{! APPLICANT TABLE }}
              <Table @isLoading={{false}}>
                <:thead>
                  {{#let
                    "py-3 pl-4 pr-3 text-left text-sm uppercase font-semibold text-gray-900"
                    as |cellClass|
                  }}
                    <tr>
                      <th scope="col" class="{{cellClass}} sm:pl-0">
                        {{t "tas.applications.applicant"}}
                      </th>
                      <th class={{cellClass}}>{{t "tas.applications.amount"}}</th>
                      <th class={{cellClass}}>{{t "tas.applications.submission_date"}}</th>
                      <th class={{cellClass}}>{{t "tas.applications.status"}}</th>
                    </tr>
                  {{/let}}
                </:thead>
                <:tbody>
                  <tr>
                    {{#let "whitespace-nowrap px-3 py-4 text-sm text-gray-500" as |cellClass|}}
                      <td
                        class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"
                      >
                        {{@model.applicant}}
                      </td>
                      <td class={{cellClass}}>
                        {{formatAsDollars @model.requestedTotal}}
                      </td>
                      <td class={{cellClass}}>
                        {{toLocaleDateString @model.courseSubmittedDate}}
                      </td>
                      <td class={{cellClass}}>
                        {{#if @model.inRepayment}}
                          <Badge @intent={{tasStateIntent @model.paymentState}}>
                            {{tasStateLabel @model.paymentState}}
                          </Badge>
                        {{else}}
                          <Badge @intent={{tasStateIntent @model.state}}>
                            {{tasStateLabel @model.state}}
                          </Badge>
                        {{/if}}
                      </td>
                    {{/let}}
                  </tr>
                </:tbody>
              </Table>
            </section>
            {{! APPLICATION DETAILS }}
            <section class="mb-12">
              <h2 class="tio-h2">{{t "tas.applications.details"}}</h2>
              <Divider class="my-2" />
              <DescriptionList class="sm:w-2/3" as |List|>
                <List.Group>
                  <List.Term>{{t "tas.applications.application_name"}}</List.Term>
                  <List.Details>{{@model.applicationName}}</List.Details>
                </List.Group>
                <List.Group>
                  <List.Term>{{t "tas.applications.start_date"}}</List.Term>
                  <List.Details>{{toLocaleDateString @model.coursesBeginDate}}</List.Details>
                </List.Group>
              </DescriptionList>
            </section>
            {{! COURSE DETAILS - EXPENSES }}
            <section class="mb-12">
              <h2 class="tio-h2">{{t "tas.applications.education"}}</h2>
              <Divider class="my-2" />
              <ActionableList @striped={{false}} as |l|>
                {{#each @model.tasCourses as |course|}}
                  <l.Row>
                    <l.Term>{{course.courseName}}</l.Term>
                    <l.Details>
                      <l.Item class="justify-self-center">
                        {{safeFormatNumber
                          (divide course.courseTotal 100)
                          style="currency"
                          currency="USD"
                        }}
                      </l.Item>
                      <l.Item
                        class="justify-self-center md:justify-self-start"
                      >{{course.courseGrade}}</l.Item>
                    </l.Details>
                  </l.Row>
                {{/each}}
              </ActionableList>
            </section>
            {{! ATTACHMENTS }}
            <section class="mb-12">
              <h2 class="tio-h2">{{t "tas.applications.attachments"}}</h2>
              <Divider class="my-2" />
              <div class="flex items-center w-full">
                <AttachmentList
                  @assets={{@model.evidenceAssets}}
                  @disabled={{true}}
                  class="w-full md:w-1/2 lg:w-1/3"
                />
              </div>
            </section>
          </div>
          <div class="col-span-1">
            <div class="max-w-sm mx-auto">
              <PaymentDetailsCard
                @isPaid={{@model.isPaid}}
                @requestedTotal={{@model.requestedTotal}}
                @payments={{@model.paymentsHistory}}
              />
              <ApplicationProcess @application={{@model}} />
            </div>
          </div>
        </div>
      </:content>
    </AppPage>

    <Modal
      @isOpen={{@controller.applicationSubmitted}}
      @onClose={{this.closeApplicationSubmittedModal}}
      class="min-h-64 !max-w-screen-sm"
      @backdrop="faded"
      as |m|
    >
      <m.Header class="text-midnight font-semibold">
        {{t "tuition_assistance.submitted.application_submitted"}}</m.Header>
      <m.Body>
        <CourseSubmitted @application={{@model}} @courseApprovalRequired={{true}} />
        <div class="mb-4 flex justify-center">
          <TioButton
            @onClick={{this.closeApplicationSubmittedModal}}
            @outlined={{true}}
            class="w-48"
          >
            {{t "common.close"}}
          </TioButton>
        </div>
      </m.Body>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedTasApplicationsShowComponent);
